import { IDeviceList } from "../../../types/statsDevices";
import { IDetailedItem } from "../../../types/statsDetailed";

export const checkDevice = (arr: IDeviceList[],  data: IDetailedItem) => {
    if (data.token !== 0) {
        return arr.map((device) => device.ident === data.token ? device.comment ? device.comment : device.name ? device.name : "Undefined Device" : "");
    } else if (data.ipv4 !== "") {
        let item = arr.filter((device) => data.ipv4 === device.ident && device.device_type !== "")[0];

        if (item) {
            return item.comment !== "" ? item.comment : item.name;
        } else {
            return data.ipv4
        }
    } else if (data.ipv6 !== "::") {
        return data.ipv6
    }
};

export const renderDetailedName = (list: IDeviceList[], data: IDetailedItem) => {
    const arr = checkDevice(list, data);

    return (
        Array.isArray(arr) ?
            arr.filter((el: any, ind: any) => arr.indexOf(el) === ind).length > 1 ?
                arr.filter((el: any, ind: any) => arr.indexOf(el) === ind) :
                "Deleted Device" :
            arr
    )
};
