import axios from "axios";
import { Dispatch } from "redux";
import { NetworksActions, NetworksActionsTypes } from "../../types/networks";
import { AgentInfoActions, AgentInfoActionsTypes } from "../../types/agentInfo";
import { AdUsersActions, AdUsersActionsTypes } from "../../types/adUsers";
import { StatsDevicesActionsTypes, ISelectedDevice, IDeviceList } from "../../types/statsDevices";

export const fetchNetworks = (user_id: string|number) => {
    return function (dispatch: Dispatch<NetworksActions>) {
        dispatch({
            type: NetworksActionsTypes.GET_NETWORKS
        });

        axios.get(`${process.env.REACT_APP_BASE_URL}/dashboard/rest_api/v1/users/${user_id}/networks/`,
        {
            withCredentials: true
        })
        .then((response) => {
            dispatch({
                type: NetworksActionsTypes.GET_NETWORKS_SUCCESS,
                payload: response.data
            });
        })
        .catch(() => {
            dispatch({
                type: NetworksActionsTypes.GET_NETWORKS_FAILURE,
                payload: 'error'
            });
        })
    };
};

export const fetchAgentInfo = (user_id: string|number) => {
    return function (dispatch: Dispatch<AgentInfoActions>) {
        dispatch({
            type: AgentInfoActionsTypes.GET_AGENT_INFO
        });

        axios.get(`${process.env.REACT_APP_BASE_URL}/agents/rest_api/v1/users/${user_id}/agentinfo/`,
        {
            withCredentials: true
        })
        .then((response) => {
            dispatch({
                type: AgentInfoActionsTypes.GET_AGENT_INFO_SUCCESS,
                payload: response.data
            });
        })
        .catch(() => {
            dispatch({
                type: AgentInfoActionsTypes.GET_AGENT_INFO_FAILURE,
                payload: 'error'
            });
        })
    };
};

export const fetchAdUsers = (user_id: string|number) => {
    return function (dispatch: Dispatch<AdUsersActions>) {
        dispatch({
            type: AdUsersActionsTypes.GET_AD_USERS
        });

        axios.get(`${process.env.REACT_APP_BASE_URL}/ad/rest_api/v1/users/${user_id}/ad_users_with_profiles/`,
        {
            withCredentials: true
        })
        .then((response) => {
            dispatch({
                type: AdUsersActionsTypes.GET_AD_USERS_SUCCESS,
                payload: response.data
            });
        })
        .catch(() => {
            dispatch({
                type: AdUsersActionsTypes.GET_AD_USERS_FAILURE,
                payload: 'error'
            });
        })
    };
};

export const deviceList = (deviceList: IDeviceList[]) => (
    {
        type: StatsDevicesActionsTypes.DEVICE_LIST,
        payload: deviceList
    }
);

export const selectedDevice = (device: ISelectedDevice) => (
    {
        type: StatsDevicesActionsTypes.SELECTED_DEVICE,
        payload: device
    }
);
