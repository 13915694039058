import axios from "axios";
import { Dispatch } from "redux";
import { IAdminsResponse, AdminActions, AdminActionsTypes } from "../../types/admins";

export const fetchAdmins = (user_id: number | string) => {
    return function (dispatch: Dispatch<AdminActions>) {
        // dispatch({
        //     type: AdminActionsTypes.GET_ADMINS_LIST
        // });

        axios.get<IAdminsResponse>(`${process.env.REACT_APP_BASE_URL}/auth/rest_api/v1/users/${user_id}/admins/`,
        {
            withCredentials: true
        })
        .then((response) => {
            dispatch({
                type: AdminActionsTypes.GET_ADMINS_LIST_SUCCESS,
                payload: response.data.results
            });
        })
        .catch(() => {
            dispatch({
                type: AdminActionsTypes.GET_ADMINS_LIST_FAILURE,
                payload: 'Oops!! Something went wrong...'
            });
        })
    };
};
