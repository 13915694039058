import axiosInstance from "./instance";
import { IReportRequestData, IReportResponseData } from "../types/statsMonthlyReport";
import { IS3IntegrationVerifyRequestData, IS3IntegrationData } from "../types/dataExport/s3Integration";
import { AxiosPromise } from "axios";

export const getRecipientsList = (userId: string|number): AxiosPromise<IReportResponseData> =>
    axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/dashboard/rest_api/v1/users/${userId}/stat_export_rule/periodic/`);

export const createRecipientsList = (userId: string|number, data: IReportRequestData): AxiosPromise<IReportResponseData> =>
    axiosInstance.post(`${process.env.REACT_APP_BASE_URL}/dashboard/rest_api/v1/users/${userId}/stat_export_rule/periodic/`, data);

export const updateRecipientsList = (userId: string|number, data: IReportRequestData): AxiosPromise<IReportResponseData> =>
    axiosInstance.patch(`${process.env.REACT_APP_BASE_URL}/dashboard/rest_api/v1/users/${userId}/stat_export_rule/periodic/`, data);

//data-export s3 integration

export const s3IntegrationVerifyCheck = (data: IS3IntegrationVerifyRequestData): AxiosPromise<any> =>
    axiosInstance.post(`${process.env.REACT_APP_BASE_URL}/dashboard/rest_api/v1/s3_integration/verify/`, data);

export const s3IntegrationCreate = (userId: string|number, data: IS3IntegrationVerifyRequestData): AxiosPromise<IS3IntegrationData> =>
    axiosInstance.post(`${process.env.REACT_APP_BASE_URL}/dashboard/rest_api/v1/users/${userId}/s3_integration/`, data);

export const s3IntegrationRead = (userId: string|number): AxiosPromise<IS3IntegrationData> =>
    axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/dashboard/rest_api/v1/users/${userId}/s3_integration/`);

export const s3IntegrationUpdate = (userId: string|number, data: IS3IntegrationVerifyRequestData): AxiosPromise<IS3IntegrationData> =>
    axiosInstance.patch(`${process.env.REACT_APP_BASE_URL}/dashboard/rest_api/v1/users/${userId}/s3_integration/`, data);

export const s3IntegrationDelete = (userId: string|number): AxiosPromise<any> =>
    axiosInstance.delete(`${process.env.REACT_APP_BASE_URL}/dashboard/rest_api/v1/users/${userId}/s3_integration/`);
