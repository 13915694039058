import { FC, useState, useEffect } from 'react';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { ICategotiesItemStats } from "../../../types/statsCategories";
import { ReactGoogleChartEvent, Chart } from "react-google-charts";
import CategoryItem from '../../stats/categories/CategoryItem';
import '../../stats/categories/Categories.scss';
import '../PdfReport.scss';

const PageFour: FC = () => {
    const categoriesDataResult = useTypedSelector(state => state.statsCategories.dataResult);
    const error = useTypedSelector(state => state.statsCategories.error);

    const [otherCats, setOtherCats] = useState<ICategotiesItemStats[]>([]);
    const [otherChartData, setOtherChartData] = useState<any[]>([]);

    const formatter = new Intl.NumberFormat('en-US');

    //chart data create
    useEffect(() => {
        let other = categoriesDataResult.filter((item: ICategotiesItemStats) => {
            if (item.cat !== undefined) {
                return !item.cat.is_dangerous
            }
        })
        setOtherCats(other);
    }, [categoriesDataResult, error]);

    interface ISumRequestData {
        blocks: number,
        requests: number,
    };

    const cybersecuritySumRequests = {
        blocks: 0,
        requests: 0,
    };

    const otherSumRequests = {
        blocks: 0,
        requests: 0,
    };

    const [cybersecurityRequestsData, setCybersecurityRequestsData] = useState<ISumRequestData>(cybersecuritySumRequests);
    const [otherRequestsData, setOtherRequestsData] = useState<ISumRequestData>(otherSumRequests);

    const sumData = (data:any[], initial: ISumRequestData) => {
        for (let key in data[0]) {
            //@ts-ignore
            initial[key] = 0;
        };

        data.reduce((acc:any, item:any) => {
            for (let key in item) {
                acc[key] += item[key];
            }
            return acc;
        }, initial);
    };

    useEffect(() => {
        let otherData: string[][] = [
            ["Category", "Requests"],
        ];

        otherCats.map((other: any, index: number) => {
            otherData.push([
                other.cat.title,
                other.requests
            ]);
        });

        sumData(otherCats, otherSumRequests)

        // sumData(cybersecurityCats, cybersecuritySumRequests)
        setCybersecurityRequestsData({
            ...cybersecurityRequestsData,
            requests: cybersecuritySumRequests.requests,
            blocks: cybersecuritySumRequests.blocks,
        });

        // sumData(otherCats, otherSumRequests)
        setOtherRequestsData({
            ...otherRequestsData,
            requests: otherSumRequests.requests,
            blocks: otherSumRequests.blocks,
        });

        setOtherChartData(otherData)
    }, [otherCats]);

    const options = {
        pieHole: 0.7,
        legend: 'none',
        pieSliceText: 'none',
        pieSliceTextStyle: {
            color: '#333',
        },
        chartArea: {
            width:"90%",
            height:"90%",
            left: 5
        },
        tooltip: {
            isHtml: true,
            textStyle: {
                color: '#333',
                fontSize: 14
            },
            showColorCode: true
        },
        backgroundColor: '#FFF'
    };

    const otherOptions = {
        ...options,
        slices: [
            {
                "id": 0,
                "color": "#2D9CDB"
            },
            {
                "id": 1,
                "color": "#F8961E"
            },
            {
                "id": 2,
                "color": "#F9C74F"
            },
            {
                "id": 3,
                "color": "#90BE6D"
            },
            {
                "id": 4,
                "color": "#F3722C"
            },
            {
                "id": 5,
                "color": "#015FCC"
            },
            {
                "id": 6,
                "color": "#4E4D4D"
            },
            {
                "id": 7,
                "color": "#C0D000"
            },
            {
                "id": 8,
                "color": "#009688"
            },
            {
                "id": 9,
                "color": "#5E4394"
            },
            {
                "id": 10,
                "color": "#219653"
            },
            {
                "id": 11,
                "color": "#BB6BD9"
            },
            {
                "id": 12,
                "color": "#BDBDBD"
            },
            {
                "id": 13,
                "color": "#706DFF"
            },
            {
                "id": 14,
                "color": "#6DB0FF"
            },
            {
                "id": 15,
                "color": "#F94144"
            },
            {
                "id": 16,
                "color": "#1C66F6"
            },
            {
                "id": 17,
                "color": "#EF676C"
            },
            {
                "id": 18,
                "color": "#008B94"
            },
            {
                "id": 19,
                "color": "#CDA7F0"
            },
            {
                "id": 20,
                "color": "#87F7D1"
            },
            {
                "id": 21,
                "color": "#7DAB63"
            },
            {
                "id": 22,
                "color": "#86BE55"
            },
            {
                "id": 23,
                "color": "#109968"
            },
            {
                "id": 24,
                "color": "#298AAF"
            },
            {
                "id": 25,
                "color": "#C86555"
            },
            {
                "id": 26,
                "color": "#9488E9"
            },
            {
                "id": 27,
                "color": "#BA28AC"
            },
            {
                "id": 28,
                "color": "#0867C3"
            },
            {
                "id": 29,
                "color": "#14168C"
            },
            {
                "id": 30,
                "color": "#4ACD7F"
            },
            {
                "id": 31,
                "color": "#219AE7"
            },
            {
                "id": 32,
                "color": "#F0BDB2"
            },
            {
                "id": 33,
                "color": "#22890F"
            },
            {
                "id": 34,
                "color": "#0D5DAD"
            },
            {
                "id": 35,
                "color": "#C67273"
            },
            {
                "id": 36,
                "color": "#98DC0C"
            },
            {
                "id": 37,
                "color": "#284B1B"
            },
            {
                "id": 38,
                "color": "#D3D08A"
            },
            {
                "id": 39,
                "color": "#C5DBDF"
            },
            {
                "id": 40,
                "color": "#B86DAC"
            },
            {
                "id": 41,
                "color": "#98B2C1"
            },
            {
                "id": 42,
                "color": "#E1732D"
            },
            {
                "id": 43,
                "color": "#869104"
            },
            {
                "id": 44,
                "color": "#D4D609"
            },
            {
                "id": 45,
                "color": "#C96426"
            },
            {
                "id": 46,
                "color": "#6DE48C"
            },
            {
                "id": 47,
                "color": "#AF2B1E"
            },
            {
                "id": 48,
                "color": "#14B91F"
            },
            {
                "id": 49,
                "color": "#AA91A4"
            },
            {
                "id": 50,
                "color": "#65D7E4"
            },
            {
                "id": 51,
                "color": "#7E8690"
            },
            {
                "id": 52,
                "color": "#928E44"
            },
            {
                "id": 53,
                "color": "#42119D"
            },
            {
                "id": 54,
                "color": "#535895"
            },
            {
                "id": 55,
                "color": "#8A8C7D"
            },
            {
                "id": 56,
                "color": "#602B66"
            },
            {
                "id": 57,
                "color": "#4F2709"
            }
        ]
    };

    const chartEvents: ReactGoogleChartEvent[] = [
        {
            eventName: "select",
            callback: ({ chartWrapper }) => {
                const chart = chartWrapper.getChart();
                const selection = chart.getSelection();

                if (selection.length === 1) {
                    const [selectedItem] = selection;
                    const dataTable = chartWrapper.getDataTable();
                    const { row, column } = selectedItem;

                    if (row != null && column != null) {
                        console.log(dataTable?.getValue(row, column));
                    } else if (row != null) {
                        console.log(dataTable?.getValue(row, 0));
                    } else if (column != null) {
                        console.log(dataTable?.getValue(0, column));
                    }
                }
            },
        },
    ];

    return (
        <div className="stats-categories-report">
            <h2 className="stats-categories-report-title">Request Category Breakdown</h2>

            <div className="stats-categories-report-data">
                <div className="stats-categories-other">
                    <div className="stats-categories-other-data">
                        <div className="stats-categories-other-data-requests">
                            <span className="stats-categories-other-data-requests-title">
                                Total unfiltered requests
                            </span>
                            <div className="stats-categories-other-data-requests-count">
                                <p>{formatter.format(otherRequestsData.requests)}</p>
                            </div>
                        </div>
                        <div className="stats-categories-other-data-blocked">
                            <span className="stats-categories-other-data-blocked-title">
                                Total blocked requests
                            </span>
                            <div className="stats-categories-other-data-blocked-count">
                                <p>{formatter.format(otherRequestsData.blocks)}</p>
                                <span>{`${Math.round(otherRequestsData.blocks * 100 / otherRequestsData.requests)} %`}</span>
                            </div>
                        </div>
                    </div>
                    <div className="stats-categories-other-content">
                        <div className="stats-categories-other-list">
                            {
                                otherCats.map((other, index:any) => (
                                    <CategoryItem
                                        key={other.cat.id}
                                        title={other.cat.title}
                                        requests={other.requests}
                                        blocks={other.blocks}
                                        colorIndex={index}
                                        colors={otherOptions.slices}
                                    />
                                ))
                            }
                        </div>
                        <div className="stats-categories-other-chart">
                            <Chart
                                chartType="PieChart"
                                width="290px"
                                height="290px"
                                data={otherChartData}
                                options={otherOptions}
                                chartEvents={chartEvents}
                            />
                            <span className="stats-categories-other-chart-total">100%</span>
                        </div>
                    </div>

                    <p className="stats-categories-report-info-title">Content category breakdown by request count, access blocks, and the percentage of each category in the total.</p>
                </div>
            </div>
        </div>
    )
};

export default PageFour;
