import React, { FC } from "react";

const TopFactsInfo: FC = () => {
    return (
        <div className="stats-top-facts-info">
            <div className="stats-top-facts-info-item">
                <div>
                    <h5 className="stats-top-facts-info-item-title">Top blocked domains</h5>
                    <p>A list of 10 domains your users constantly tried to access, falling within blocked categories or pointwise blocked by you.</p>
                </div>
            </div>
            <div className="stats-top-facts-info-item">
                <div>
                    <h5 className="stats-top-facts-info-item-title">Top blocked devices</h5>
                    <p>A list of 10 devices that most frequently attempted to access the domains and categories you have blocked.</p>
                </div>
            </div>
            <div className="stats-top-facts-info-item">
                <div>
                    <h5 className="stats-top-facts-info-item-title">Top blocked categories</h5>
                    <p>A list of the 10 categories that your users consistently tried to access, falling within the blocking settings of your account.</p>
                </div>
            </div>
        </div>
    )
};

export default TopFactsInfo;
