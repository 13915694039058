import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { useActions } from '../../../hooks/useActions';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { StatsHighlightsActionsTypes } from "../../../types/statsHighlights";
import { Chart } from "react-google-charts";
import { useCheckBreakpoint } from '../../../hooks/useCheckBreakpoint';
import { checkCount } from '../statsCount';
import Endpoints from '../../../api/endpoints';
import InfoBlockAccordion from '../../UI/info-block-accordion/InfoBlockAccordion';
import HighlightsInfo from './HighlightsInfo';
import UpdateStatsButton from '../updateStatsButton';
import Loader from '../../UI/loader/Loader';
import FetchError from '../../UI/fetch-error/FetchError';
import './Highlights.scss';

const Highlights: FC = () => {
    const dispatch = useDispatch();
    const { statsHighlightsRequest } = useActions();
    const { STATS } = Endpoints;

    const token = useTypedSelector(state => state.auth.token);
    const profiles = useTypedSelector(state => state.profiles.profiles);
    const period = useTypedSelector(state => state.period.period);
    const requestData = useTypedSelector(state => state.statsRequestData.requestData);
    const [highlightsDataJson, setHighlightsDataJson] = useState<string>('');
    const [highlightsDataResult, setHighlightsDataResult] = useState<any>([]);
    const [lastUpdate, setLastUpdate] = useState<string>('');
    const [chartData, setChartData] = useState<any[]>([]);

    const loading = useTypedSelector(state => state.statsHighlights.loading);
    const error = useTypedSelector(state => state.statsHighlights.error);

    const formatter = new Intl.NumberFormat('en-US');

    //get highlights stats

    let recursionCount = 0;

    const getHighlightsStats = () => {
        dispatch({
            type: StatsHighlightsActionsTypes.GET_STATS_HIGHLIGHTS
        });

        axios.post(`${process.env.REACT_APP_STATS_URL}${STATS.HIGHLIGHTS}`, requestData, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then(response => {
            if (response.data.status.includes('complete')) {
                setHighlightsDataJson(response.data.message);
                setLastUpdate(response.data.updated);

                return dispatch({
                    type: StatsHighlightsActionsTypes.GET_STATS_HIGHLIGHTS_SUCCESS,
                    payload: response.data.message
                });
            } else {
                return setTimeout(() => {
                    recursionCount++

                    //exit from recursion with a long query
                    if (recursionCount >= 20) {
                        return dispatch({
                            type: StatsHighlightsActionsTypes.GET_STATS_HIGHLIGHTS_FAILURE,
                            payload: 'Oops! Something went wrong... Please, try later.'
                        });
                    } else {
                        return getHighlightsStats();
                    }
                }, 1000);
            }
        })
        .catch(() => {
            dispatch({
                type: StatsHighlightsActionsTypes.GET_STATS_HIGHLIGHTS_FAILURE,
                payload: 'Oops! Something went wrong... Please, try later.'
            });
        });
    };

    useEffect(() => {
        if (period !== 'Range' && requestData.profile_ids.length !== 0) {
            getHighlightsStats();
        }

        if (profiles.length !== 0) {
            statsHighlightsRequest(getHighlightsStats);
        }
    }, [requestData]);

    //get stats to json
    useEffect(() => {
        axios.get(highlightsDataJson, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then((response) => {
            setHighlightsDataResult(response.data);

            return dispatch({
                type: StatsHighlightsActionsTypes.STATS_HIGHLIGHTS_DATA_RESULT,
                payload: response.data
            });
        })
        .catch(() => {
            console.log('error');
        })
    }, [highlightsDataJson]);

    //chart data

    const {
        requests: highlights_requests,
        blocks: highlights_blocks,
        dangerous_blocks: highlights_dangerous_blocks
    } = highlightsDataResult;

    useEffect(() => {
        {error || highlights_requests === 0 ?
            setChartData(
                [
                    ["From", "To", "Requests", {'type': 'string', 'role': 'tooltip', 'p': {'html': true}}],
                    ["Total unfiltered", "Allowed", 1, customTooltip(0, 'Allowed requests')],
                    ["Total unfiltered", "Blocked", 1, customTooltip(0, 'Total blocked requests')],
                    ["Total unfiltered", "Threats", 1, customTooltip(0, 'Total threats prevented')],
                ]
            ) :
            setChartData(
                [
                    ["From", "To", "Requests", {'type': 'string', 'role': 'tooltip', 'p': {'html': true}}],
                    ["Total unfiltered", "Allowed", 1, customTooltip(highlights_requests - highlights_blocks, 'Allowed requests')],
                    ["Total unfiltered", "Blocked", 1, customTooltip(highlights_blocks - highlights_dangerous_blocks, 'Total blocked requests')],
                    ["Total unfiltered", "Threats", 1, customTooltip(highlights_dangerous_blocks, 'Total threats prevented')],
                ].filter(item => item !== null)
            )
        }
    }, [highlightsDataResult, error]);

    const statsCount = (count: number, title: string, type: string) => {
        return (
            <div className={`stats-highlights-chart-${type}`}>
                <span>{title}</span>
                <p>{checkCount(count)}</p>
            </div>
        )
    };

    const customTooltip = (requests: number, text: string) => {
        return `<div style="padding: 8px;">
                    <p style="color: '#333'; font-size: 14px; font-weight: 500;">Total Unfiltered Requests -> ${text}</p>
                    <p style="color: '#333'; font-size: 14px;">Requests: ${formatter.format(requests)}</p>
                </div>`
    };

    const colors = ['#99B7D8', '#219653', '#EC7D0D', '#5E4394', '#A7D6BB', '#ffff99', '#1f78b4', '#F8CC9F'];
    const options = {
        sankey: {
            node: {
                interactivity: false,
                labelPadding: 6,
                nodePadding: 105,
                colors: colors,
                label: {
                    fontName: 'Roboto", sans-serif',
                    fontSize: 12,
                    color: '#051226',
                }
            },
            link: {
                colorMode: 'gradient',
                colors: colors
            }
        },
        tooltip: {
            isHtml: true
        }
    };

    return (
        <div className="stats-highlights">
            <h2 className="stats-highlights-title">Filtering Highlights</h2>

            <div style={{ position: 'relative' }}>
                <div
                    className="stats-highlights-chart"
                    style={{
                        opacity: loading || error || highlights_requests === 0 ? 0.2 : 1,
                        pointerEvents: loading || error || highlights_requests === 0 ? 'none' : 'auto'
                    }}
                >
                    <div className="stats-highlights-chart-data">
                        {
                            error || highlights_requests === 0 ?
                            statsCount(0, 'Total unfiltered requests', 'all') :
                            statsCount(loading ? 0 : highlights_requests, 'Total unfiltered requests', 'all')
                        }

                    </div>

                    <Chart
                        chartType="Sankey"
                        width={useCheckBreakpoint(576) ? "" : "600px"}
                        height="288px"
                        data={chartData}
                        options={options}
                    />

                    {error || highlights_requests === 0 ?
                        <div>
                            {statsCount(loading ? 0 : 0, 'Allowed requests', 'filtered')}
                            {statsCount(loading ? 0 : 0, 'Total blocked requests', 'blocked')}
                            {statsCount(loading ? 0 : 0, 'Total threats prevented', 'threats')}
                        </div>
                        :
                        <div>
                            {statsCount(loading ? 0 : highlights_requests - highlights_blocks, 'Allowed requests', 'filtered')}
                            {statsCount(loading ? 0 : highlights_blocks - highlights_dangerous_blocks, 'Total blocked requests', 'blocked')}
                            {statsCount(loading ? 0 : highlights_dangerous_blocks, 'Total threats prevented', 'threats')}
                        </div>
                    }
                </div>

                <UpdateStatsButton
                    updateStats={getHighlightsStats}
                    lastUpdate={lastUpdate}
                    disabledTimeout={5000}
                />

                {loading &&
                    <Loader
                        text='Please wait...'
                    />
                }

                {error &&
                    <FetchError
                        text={error}
                        isButton={true}
                        isButtonDisabled={loading ? true : false}
                        isIcon={false}
                        clickHeandler={getHighlightsStats}
                    />
                }

                {!error && highlights_requests === 0 &&
                    <FetchError
                        text={loading ? "" : "You don’t have stats yet"}
                        isButton={true}
                        isButtonDisabled={loading ? true : false}
                        isIcon={false}
                        clickHeandler={getHighlightsStats}
                    />
                }
            </div>

            <InfoBlockAccordion
                id="highlights"
                title="The general highlights of your request flow and the total count of specific request types."
                margin="32px 0 0"
                is_content={true}
                content={<HighlightsInfo />}
            />
        </div>
    )
};

export default Highlights;
