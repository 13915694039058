import { FC, useContext } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { downloadPdf } from "../../downloadPdf";
import PdfReportContext from "../../contexts/pdfReportContext";
import PageOne from "./pages/Page01";
import PageTwo from "./pages/Page02";
import PageThree from "./pages/Page03";
import PageFour from "./pages/Page04";
import PageFive from "./pages/Page05";
import PageSix from "./pages/Page06";
import PageSeven from "./pages/Page07";
import './PdfReport.scss';

const PdfReport: FC = () => {
    const { isShowReportPreview, setIsShowReportPreview } = useContext(PdfReportContext);
    const authData = useTypedSelector(state => state.auth.authData);
    const requestData = useTypedSelector(state => state.statsRequestData.requestData);

    const onToggleReportPreview = (): void => {
        return (
            setIsShowReportPreview(!isShowReportPreview)
        );
    };

    const onToggleReportDownload = (): void => {
        return (
            downloadPdf()
        );
    };

    return (
        <>
            {isShowReportPreview &&
                <div>
                    <div className="pdf-report-backdrop"></div>
                    <div className="pdf-report-controls">
                        <button
                            type="button"
                            onClick={onToggleReportDownload}
                            className="pdf-report-controls-download"
                        />
                        <button
                            type="button"
                            onClick={onToggleReportPreview}
                            className="pdf-report-controls-close"
                        />
                    </div>
                    <div className="pdf-report">
                        <div className="pdf-report-page">
                            <div className="pdf-report-page-content">
                                <div className="pdf-report-page-head">
                                    <div className="pdf-report-page-logo"></div>
                                    <div className="pdf-report-page-date">
                                        <span>{requestData.period === "range" ? `${requestData.start} - ${requestData.end}` : requestData.period}</span>
                                    </div>
                                    <h1 className="pdf-report-page-title">Filtering report</h1>
                                    <p className="pdf-report-page-account">
                                        {authData && authData.username}
                                    </p>
                                </div>

                                <PageOne />
                                <PageTwo />
                                <PageThree />
                                <PageFour />
                                <PageFive />
                                <PageSix />
                                <PageSeven />

                                <div className="pdf-report-page-footer">
                                    <div className="pdf-report-page-footer-message">
                                        <p>Thank you for using our service, if you have any questions or suggestions, we are happy to answer.</p>
                                    </div>
                                    <div className="pdf-report-page-footer-contacts">
                                        <a
                                            href="https://www.safedns.com/"
                                            className="pdf-report-page-footer-contacts-website"
                                        >
                                            www.safedns.com
                                        </a>

                                        <div className="pdf-report-page-footer-contacts-phone-usa">
                                            <p>USA</p>
                                            <a href="tel:+1 (800) 820-2530">+1 (800) 820-2530</a>
                                        </div>

                                        <a
                                            href="mailto:sales@safedns.com"
                                            className="pdf-report-page-footer-contacts-mail"
                                        >
                                            sales@safedns.com
                                        </a>

                                        <div className="pdf-report-page-footer-contacts-phone-all">
                                            <p>All</p>
                                            <a href="tel:+1 (571) 421-2990">+1 (571) 421-2990</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};

export default PdfReport;
