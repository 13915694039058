import React, { FC } from "react";

const CategoriesInfo: FC = () => {
    return (
        <div className="stats-categories-info">
            <h5 className="stats-categories-info-title">If you notice a discrepancy between the number of blocks and requests, it could be due to:</h5>
            <div className="stats-categories-info-text">
                <p className="stats-categories-info-text-item">Domains from this category have been unblocked in your personal account settings for part of the selected period.</p>
                <p className="stats-categories-info-text-item">The domain previously belonged to a different category or was uncategorized but now shows characteristics of a threat or content blocked by personal account settings.</p>
                <p className="stats-categories-info-text-item">Unauthorized access to your personal account or network by an internal or external attacker.</p>
                <p className="stats-categories-info-text-item">An administrator allowed this category within your settings.</p>
            </div>
            <p>If you suspect the mismatch is not due to your actions, please check your account logs or tell us you are experiencing a network attack.</p>
        </div>
    )
};

export default CategoriesInfo;
