import axios from "axios";
import { Dispatch } from "redux";
import { ProfilesActions, ProfilesActionsTypes } from "../../types/profiles";

export const fetchProfiles = (user_id: number | string) => {
    return function (dispatch: Dispatch<ProfilesActions>) {
        dispatch({
            type: ProfilesActionsTypes.GET_PROFILES
        });

        axios.get(`${process.env.REACT_APP_BASE_URL}/dashboard/rest_api/v1/users/${user_id}/profiles/`,
        {
            withCredentials: true
        })
        .then((response) => {
            dispatch({
                type: ProfilesActionsTypes.GET_PROFILES_SUCCESS,
                payload: response.data.results
            });
        })
        .catch(() => {
            dispatch({
                type: ProfilesActionsTypes.GET_PROFILES_FAILURE,
                payload: 'error'
            });
        })
    };
};
