import React, { FC } from "react";
import { uid } from "../../../uid";
import "./Select.scss";

interface SelectProps {
    id?: string;
    name?: string;
    value?: string;
    options: string[];
    width?: string;
    label?: string;
    changeHeandler: React.ChangeEventHandler<HTMLSelectElement>;
    isDisabled?: boolean;
};

const Select: FC<SelectProps> = ({ id, name, value, options, width, label, changeHeandler, isDisabled }) => {
    return (
        <div className="select">
            {label &&
                <label>{label}</label>
            }
            <select
                id={id}
                name={name}
                value={value}
                onChange={changeHeandler}
                disabled={isDisabled}
                style={{width: width}}
            >
                {options.map((option: any) => (
                    <option key={uid()} id={option.id} value={option.value}>{option.name}</option>
                ))}
            </select>
        </div>
    );
};

export default Select;
