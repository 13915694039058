export enum StatsRequestDataActionsTypes {
    REQUEST_DATA = "REQUEST_DATA"
};

export interface IRequestData {
    tokens: string|number[],
    period: string,
    profile_ids: number[],
    start: string|Date,
    end: string|Date,
    group_by: string,
    order_by?: string,
    timezone: string,
    limit: number,
    domains: string[],
    ipv4_addresses: string|number[],
    ipv6_networks: string|number[],
    file_types: string[],
    cache_ttl: number
}

export interface IInitialState {
    requestData: IRequestData,
};

interface IStatsRequestData {
    type: StatsRequestDataActionsTypes.REQUEST_DATA,
    payload: IRequestData
};

export type StatsRequestDataActions = IStatsRequestData;
