import React, { FC, useState, useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { StatsDomainsActionsTypes } from "../../../types/statsDomains";
import { IDeviceItem } from "../../../types/statsDevices";
import { renderDeviceName } from "../devices/DeviceItemName";
import { useDispatch } from "react-redux";
import ProgressLoader from "../../UI/progress-loader/ProgressLoader";
import FetchError from "../../UI/fetch-error/FetchError";
import './Domains.scss';

const DomainItem: FC<any> = ({
        domain,
        category,
        requests,
        blocks,
        sortChange,
        filteredCategoryChange,
        filteredStatusChange,
        paginationChange
    }
    ) => {
    const dispatch = useDispatch();
    const token = useTypedSelector(state => state.auth.token);
    const requestData = useTypedSelector(state => state.statsRequestData.requestData);
    const deviceList = useTypedSelector(state => state.statsDevices.deviceList);
    const error = useTypedSelector(state => state.statsDomains.getDevicesError);

    const [itemIsShow, setItemIsShow] = useState<boolean>(false);
    const [requestDomainData, setRequestDomainData] = useState(requestData);
    const [devicesDataJson, setDevicesDataJson] = useState<string>('');
    const [devicesDataResult, setDevicesDataResult] = useState<any[]>([]);
    const [status, setStatus] = useState<string>('');
    const [loadingState, setLoadingState] = useState<boolean>(false)

    const formatter = new Intl.NumberFormat('en-US');

    useEffect(() => {
        if (blocks === requests) {
            setStatus('Blocked')
        } else if (blocks === 0) {
            setStatus('Allowed')
        } else {
            setStatus('Mixed')
        };
    }, [requests, blocks]);

    const domainItemHandler = (e: any) => {
        setRequestDomainData({ ...requestData, domains: [e.currentTarget.title], limit: 5 });
        setItemIsShow(!itemIsShow);
    };

    //get devices

    let recursionCount = 0;

    const getDevices = () => {
        setLoadingState(true);
        dispatch({
            type: StatsDomainsActionsTypes.GET_STATS_DOMAIN_DEVICES
        });

        axios.post(`${process.env.REACT_APP_STATS_URL}/api/rest/v2/get_devices_activity/`, requestDomainData, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then(response => {
            if (response.data.status.includes('complete')) {
                setLoadingState(false);
                setDevicesDataJson(response.data.message);

                return dispatch({
                    type: StatsDomainsActionsTypes.GET_STATS_DOMAIN_DEVICES_SUCCESS,
                    payload: response.data.message
                });
            } else {
                return setTimeout(() => {
                    recursionCount++

                    //exit from recursion with a long query
                    if (recursionCount >= 20) {
                        return dispatch({
                            type: StatsDomainsActionsTypes.GET_STATS_DOMAIN_DEVICES_FAILURE,
                            payload: 'Oops! Something went wrong... Please, try later.'
                        });
                    } else {
                        return getDevices();
                    }
                }, 1000);
            }
        })
        .catch(() => {
            dispatch({
                type: StatsDomainsActionsTypes.GET_STATS_DOMAIN_DEVICES_FAILURE,
                payload: 'Oops! Something went wrong... Please, try later.'
            });
        });
    };

    // get devices to json
    useEffect(() => {
        axios.get(devicesDataJson, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then((response) => {
            setDevicesDataResult(Array.from(response.data));
        })
        .catch(() => {
            console.log('error');
        })
    }, [devicesDataJson]);

    useEffect(() => {
        if (itemIsShow) {
            getDevices();
        }
    }, [itemIsShow, token]);

    //close devices detailed when sorted or pagination change
    useEffect(() => {
        setItemIsShow(false);
    }, [sortChange, filteredCategoryChange, filteredStatusChange, paginationChange]);

    return (
        <div className="stats-domains-list">
            <ul
                className="stats-domains-list-item"
                title={domain}
                onClick={(e) => domainItemHandler(e)}
                style={{ backgroundColor: itemIsShow ? "rgba(0, 104, 255, 0.04)" : "" }}
            >
                <button
                    type="button"
                    value={domain}
                    className={`stats-domains-list-item-${itemIsShow ? "open" : "close"}`}
                />
                <li className="stats-domains-list-item-domain">{domain}</li>
                <li className="stats-domains-list-item-category">{category ? category : "Internal category"}</li>
                <li>{formatter.format(requests)}</li>
                <li>
                    <span className={`status-${status.toLocaleLowerCase()}`}>
                        {status}
                    </span>
                </li>
            </ul>

            {itemIsShow &&
                <div className="stats-domains-list-item-devices">
                    {loadingState && !error &&
                        <ProgressLoader />
                    }

                    {!loadingState &&
                        <div
                            style={{
                                opacity: loadingState || error ? 0.2 : 1,
                                pointerEvents: loadingState || error ? 'none' : 'auto'
                            }}
                        >
                            <ul className="stats-domains-list-item-devices-header">
                                <li>Device</li>
                                <li>Requests</li>
                                <li>Blocked</li>
                                <li>Status</li>
                            </ul>

                            {devicesDataResult && devicesDataResult.map((item: IDeviceItem, index: number) => (
                                item.requests &&
                                <ul key={index} className="stats-domains-list-item-devices-item">
                                    <li>
                                        {renderDeviceName(deviceList, item)}
                                    </li>
                                    <li>{item.requests}</li>
                                    <li>{item.blocks}</li>
                                    <li>
                                        <span className={`status-${item.requests === item.blocks ? 'blocked' : item.blocks === 0 ? 'allowed' : 'mixed'}`}>
                                            {item.requests === item.blocks ? 'Blocked' : item.blocks === 0 ? 'Allowed' : 'Mixed'}
                                        </span>
                                    </li>
                                </ul>
                            ))}

                            <NavLink to="/dashboard/new_statistics/detailed" className="stats-domains-list-item-devices-detailed">Detailed report</NavLink>
                        </div>
                    }

                    {error &&
                        <FetchError
                            text={error}
                            isButton={false}
                            isButtonDisabled={loadingState ? true : false}
                            isIcon={false}
                            align="center"
                            clickHeandler={getDevices}
                        />
                    }
                </div>
            }
        </div>
    )
};

export default DomainItem;
