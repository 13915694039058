import { FC, useState, useEffect } from 'react';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { ICategotiesItemStats } from "../../../types/statsCategories";
import { ReactGoogleChartEvent, Chart } from "react-google-charts";
import CategoryItem from '../../stats/categories/CategoryItem';
import CategoriesInfo from '../../stats/categories/CategoriesInfo';
import '../../stats/categories/Categories.scss';
import '../PdfReport.scss';

const PageThree: FC = () => {
    const categoriesDataResult = useTypedSelector(state => state.statsCategories.dataResult);
    const lastMonthDataResult = useTypedSelector(state => state.statsCategories.lastMontnDataResult);
    const error = useTypedSelector(state => state.statsCategories.error);

    const [cybersecurityCats, setCybersecurityCats] = useState<ICategotiesItemStats[]>([]);
    const [cybersecurityChartData, setCybersecurityChartData] = useState<any[]>([]);

    const formatter = new Intl.NumberFormat('en-US');

    //chart data create
    useEffect(() => {
        let cybersecurity = categoriesDataResult?.filter((item: ICategotiesItemStats) => {
            if (item.cat !== undefined) {
                return item.cat.is_dangerous
            }
        });

        setCybersecurityCats(cybersecurity);
    }, [categoriesDataResult, error]);

    interface ISumRequestData {
        blocks: number,
        requests: number,
    };

    const cybersecuritySumRequests = {
        blocks: 0,
        requests: 0,
    };

    const [cybersecurityRequestsData, setCybersecurityRequestsData] = useState<ISumRequestData>(cybersecuritySumRequests);

    const sumData = (data:any[], initial: ISumRequestData) => {
        for (let key in data[0]) {
            //@ts-ignore
            initial[key] = 0;
        };

        data.reduce((acc:any, item:any) => {
            for (let key in item) {
                acc[key] += item[key];
            }
            return acc;
        }, initial);
    };

    useEffect(() => {
        let cybersecurityData: string[][] = [
            ["Category", "Requests"],
        ];

        cybersecurityCats.map((cybersecurity: any, index: number) => {
            cybersecurityData.push([
                cybersecurity.cat.title,
                cybersecurity.requests
            ]);
        });

        sumData(cybersecurityCats, cybersecuritySumRequests)

        // sumData(cybersecurityCats, cybersecuritySumRequests)
        setCybersecurityRequestsData({
            ...cybersecurityRequestsData,
            requests: cybersecuritySumRequests.requests,
            blocks: cybersecuritySumRequests.blocks,
        });

        setCybersecurityChartData(cybersecurityData);
    }, [cybersecurityCats]);

    const options = {
        pieHole: 0.7,
        legend: 'none',
        pieSliceText: 'none',
        pieSliceTextStyle: {
            color: '#333',
        },
        chartArea: {
            width:"90%",
            height:"90%",
            left: 5
        },
        tooltip: {
            isHtml: true,
            textStyle: {
                color: '#333',
                fontSize: 14
            },
            showColorCode: true
        },
        backgroundColor: 'FFF'
    };

    const cybersecurityOptions = {
        ...options,
        slices: [
            {
                "id": 0,
                "color": "#2D9CDB"
            },
            {
                "id": 1,
                "color": "#F8961E"
            },
            {
                "id": 2,
                "color": "#F9C74F"
            },
            {
                "id": 3,
                "color": "#90BE6D"
            },
            {
                "id": 4,
                "color": "#F3722C"
            },
            {
                "id": 5,
                "color": "#5E4394"
            },
            {
                "id": 6,
                "color": "#F94144"
            }
        ]
    };

    const chartEvents: ReactGoogleChartEvent[] = [
        {
            eventName: "select",
            callback: ({ chartWrapper }) => {
                const chart = chartWrapper.getChart();
                const selection = chart.getSelection();

                if (selection.length === 1) {
                    const [selectedItem] = selection;
                    const dataTable = chartWrapper.getDataTable();
                    const { row, column } = selectedItem;

                    if (row != null && column != null) {
                        console.log(dataTable?.getValue(row, column));
                    } else if (row != null) {
                        console.log(dataTable?.getValue(row, 0));
                    } else if (column != null) {
                        console.log(dataTable?.getValue(0, column));
                    }
                }
            },
        },
    ];

    const cybRequestsRatio = cybersecurityRequestsData.requests - lastMonthDataResult.dangerous_blocks;

    return (
        <div className="stats-categories-report">
            <h2 className="stats-categories-report-title">Request Category Breakdown</h2>

            <div className="stats-categories-report-data">
                <div className="stats-categories-cybersecurity">
                    <div className="stats-categories-cybersecurity-data">
                        <div className="stats-categories-cybersecurity-data-requests">
                            <span className="stats-categories-cybersecurity-data-requests-title">
                                Total malicious requests
                            </span>
                            <div className="stats-categories-cybersecurity-data-requests-count">
                                <p>{formatter.format(cybersecurityRequestsData.requests)}</p>
                                <div
                                    className="stats-categories-cybersecurity-data-requests-count-last-month"
                                    style={{ color: Math.sign(cybRequestsRatio) ? '#EB5757' : '#219653' }}
                                >
                                    <span className={`stats-categories-cybersecurity-data-requests-count${Math.sign(cybRequestsRatio) ? '-up' : '-down'}`} />
                                    <span>{`${formatter.format(cybRequestsRatio)} (${lastMonthDataResult.dangerous_blocks ? Math.round((cybRequestsRatio) * 100 / lastMonthDataResult.dangerous_blocks) : '-'}%)`}</span>
                                    <span style={{ color: '#4F4F4F' }}>vs last month</span>
                                </div>
                            </div>
                        </div>
                        <div className="stats-categories-cybersecurity-data-blocked">
                            <span className="stats-categories-cybersecurity-data-blocked-title">
                                Total threats prevented
                            </span>
                            <div className="stats-categories-cybersecurity-data-blocked-count">
                                <p>{formatter.format(cybersecurityRequestsData.blocks)}</p>
                                <span>{`${Math.round(cybersecurityRequestsData.blocks * 100 / cybersecurityRequestsData.requests)}%`}</span>
                            </div>
                        </div>
                    </div>
                    <div className="stats-categories-cybersecurity-content">
                        <div className="stats-categories-cybersecurity-list">
                            {
                                cybersecurityCats.map((cybersecurity, index: any) => (
                                    <CategoryItem
                                        key={cybersecurity.cat.id}
                                        title={cybersecurity.cat.title}
                                        requests={cybersecurity.requests}
                                        blocks={cybersecurity.blocks}
                                        colorIndex={index}
                                        colors={cybersecurityOptions.slices}
                                    />
                                ))
                            }
                        </div>
                        <div className="stats-categories-cybersecurity-chart">
                            <Chart
                                chartType="PieChart"
                                width="290px"
                                height="290px"
                                data={cybersecurityChartData}
                                options={cybersecurityOptions}
                                chartEvents={chartEvents}
                            />
                            <span className="stats-categories-cybersecurity-chart-total">100%</span>
                        </div>
                    </div>
                </div>
            </div>
            <CategoriesInfo />
        </div>


    )
};

export default PageThree;
