import axios from "axios";
import { Dispatch } from "redux";
import { NotificationActions, NotificationActionsTypes } from "../../types/notification";
import { IRequestData } from "../../types/statsRequestData";

export const fetchStatsExport = (user_id: number | string, data: IRequestData) => {
    return function (dispatch: Dispatch<NotificationActions>) {
        dispatch({
            type: NotificationActionsTypes.NOTIFICATION,
            payload: {
                type: "",
                message: "",
                isNotification: false
            }
        });

        axios.post(`${process.env.REACT_APP_BASE_URL}/dashboard/rest_api/v1/users/${user_id}/stat_export_rule/single/`, {...data},
        {
            withCredentials: true
        })
        .then(() => {
            dispatch({
                type: NotificationActionsTypes.NOTIFICATION,
                payload: {
                    type: 'success',
                    message: "A report with statistics will be sent to you by email.",
                    isNotification: true
                }
            });
        })
        .catch((error) => {
            dispatch({
                type: NotificationActionsTypes.NOTIFICATION,
                payload: {
                    type: 'error',
                    message: error.response.data.detail,
                    isNotification: true
                }
            });
        })
    };
};
