import { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import AmazonS3DataExportForm from "./AmazonS3DataExportForm";
import Navigation from "../../controls/Navigation";
import ContentBlock from "../../UI/content-block/ContentBlock";
import DataExportStepsBar from "../DataExportStepsBar";
import DataExportStatus from "../DataExportStatus";
import '../DataExport.scss';

const AmazonS3DataExportEdit: FC = () => {
    const location = useLocation();
    const state = JSON.parse(location.state);

    const s3IntegrationData = useTypedSelector(state => state.dataExportS3Integration.s3IntegrationReadData);

    const [includeColumnHeaders, setIncludeColumnHeaders] = useState<boolean>(s3IntegrationData ? s3IntegrationData.include_headers : false);
    const [bucket, setBucket] = useState<string>(s3IntegrationData ? s3IntegrationData.bucket : "");
    const [accessKeyId, setAccessKeyId] = useState<string>(s3IntegrationData ? s3IntegrationData.access_key : "");
    const [keyPrefix, setKeyPrefix] = useState<string>(s3IntegrationData ? s3IntegrationData.prefix : "");
    const [endpoint, setEndpoint] = useState<string>(s3IntegrationData ? s3IntegrationData.endpoint : "");
    const [secretAccessKey, setSecretAccessKey] = useState<string>(s3IntegrationData ? s3IntegrationData.secret_key : "");

    return (
        <div className="data-export-check">
            <div className="controls">
                <Navigation />
            </div>
            <DataExportStepsBar />

            <ContentBlock
                title={state.title}
                padding="0"
                content={
                    <>
                        <div className="data-export-check-content">
                            <DataExportStatus
                                status={state.status}
                                lastExport={state.lastExport}
                            />
                        </div>

                        <AmazonS3DataExportForm
                            bucket={bucket}
                            setBucket={setBucket}
                            keyPrefix={keyPrefix}
                            setKeyPrefix={setKeyPrefix}
                            accessKeyId={accessKeyId}
                            setAccessKeyId={setAccessKeyId}
                            endpoint={endpoint}
                            setEndpoint={setEndpoint}
                            secretAccessKey={secretAccessKey}
                            setSecretAccessKey={setSecretAccessKey}
                            includeColumnHeaders={includeColumnHeaders}
                            setIncludeColumnHeaders={setIncludeColumnHeaders}
                            formType={"edit"}
                        />
                    </>
                }
            />
        </div>
    );
};

export default AmazonS3DataExportEdit;
