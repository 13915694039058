import { FC, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import ModalContext from "../../../contexts/modalContext";
import Input from "../../UI/input/Input";
import ButtonLink from "../../UI/button/ButtonLink";
import Navigation from "../../controls/Navigation";
import ContentBlock from "../../UI/content-block/ContentBlock";
import DataExportStepsBar from "../DataExportStepsBar";
import DataExportStatus from "../DataExportStatus";
import '../DataExport.scss';

const AmazonS3DataExportCheck: FC = () => {
    const location = useLocation();
    const state = JSON.parse(location.state);

    const { isShowS3IntegrationDeleteModal, setIsShowS3IntegrationDeleteModal } = useContext(ModalContext);
    const s3IntegrationData = useTypedSelector(state => state.dataExportS3Integration.s3IntegrationReadData);

    const data = JSON.stringify({
        id: state.id,
        title: state.title,
        description: state.description,
        isCompleted: state.isCompleted,
        status: state.status,
        lastExport: state.lastExport
    });

    const deleteIntegrationNotice = () => {
        setIsShowS3IntegrationDeleteModal(!isShowS3IntegrationDeleteModal);
    };

    return (
        <div className="data-export-check">
            <div className="controls">
                <Navigation />
            </div>
            <DataExportStepsBar />

            <ContentBlock
                title={state.title}
                padding="0"
                content={
                    <div className="data-export-check-content">
                        <DataExportStatus
                            status={state.status}
                            lastExport={state.lastExport}
                        />

                        <div className="data-export-check-form">
                            <div className="data-export-check-form-item">
                                <Input
                                    type="text"
                                    name="bucket"
                                    width="512px"
                                    value={s3IntegrationData ? s3IntegrationData.bucket : ""}
                                    label="Bucket"
                                    isDisabled={true}
                                />
                            </div>
                            <div className="data-export-check-form-item">
                                <Input
                                    type="text"
                                    name="keyPrefix"
                                    width="512px"
                                    value={s3IntegrationData ? s3IntegrationData.prefix : ""}
                                    label="Key prefix"
                                    isDisabled={true}
                                />
                            </div>
                            <div className="data-export-check-form-item">
                                <Input
                                    type="text"
                                    name="endpoint"
                                    width="512px"
                                    value={s3IntegrationData ? s3IntegrationData.endpoint : ""}
                                    label="Endpoint"
                                    isDisabled={true}
                                />
                            </div>
                            <div className="data-export-check-form-item">
                                <Input
                                    type="text"
                                    name="accessKeyId"
                                    width="512px"
                                    value={s3IntegrationData ? s3IntegrationData.access_key : ""}
                                    label="Access key ID"
                                    isDisabled={true}
                                />
                            </div>
                            <div className="data-export-check-form-item">
                                <Input
                                    type="password"
                                    name="secretAccessKey"
                                    width="512px"
                                    value={s3IntegrationData ? s3IntegrationData.secret_key : ""}
                                    label="Secret access key"
                                    isDisabled={true}
                                />
                            </div>
                        </div>

                        <div className="data-export-check-controls">
                            <div className="data-export-check-controls">
                                <ButtonLink
                                    link="/dashboard/new_statistics/data-export/"
                                    name="Back"
                                    modifier="secondary"
                                    width="72px"
                                />

                                <div className="data-export-check-controls-delete-wrapper">
                                    <button
                                        type="button"
                                        className="data-export-check-controls-delete"
                                        onClick={deleteIntegrationNotice}
                                    />
                                    <div className="data-export-check-controls-delete-tooltip">
                                        <p className="data-export-check-controls-delete-tooltip-text">Delete service</p>
                                    </div>
                                </div>
                            </div>

                            <ButtonLink
                                link={`/dashboard/new_statistics/data-export/edit/${state.id}`}
                                name="Edit"
                                modifier="primary"
                                width="100px"
                                state={data}
                            />
                        </div>
                    </div>
                }
            />
        </div>
    );
};

export default AmazonS3DataExportCheck;
