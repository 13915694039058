import { FC } from "react";
import './DataExport.scss';

const DataExportStepsBar: FC = () => {
    return (
        <div className="data-export-steps">
            <div className="data-export-steps-one">
                <span>1</span>
                <p>Choose the service</p>
            </div>
            <span className="data-export-steps-arrow" />
            <div className="data-export-steps-two">
                <span>2</span>
                <p>Configure & Verify Connection</p>
            </div>
        </div>
    );
};

export default DataExportStepsBar;
