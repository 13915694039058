import { FC, useContext } from "react";
import { NavLink } from "react-router-dom";
import { useCheckBreakpoint } from "../../hooks/useCheckBreakpoint";
import AuthContext from "../../contexts/authContext";
import NavbarContext from "../../contexts/navbarContext";
import MenuItem from "./MenuItem";
import './Menu.scss';

const Menu: FC = () => {
    const { navbar } = useContext(AuthContext);
    const { isShowNavbar } = useContext(NavbarContext);

    const renderMenu = () => {
        return (
            <>
                {navbar.map((item: any, index: any) => (
                    item.children.length ?

                    <MenuItem
                        key={index}
                        title={item.title}
                        list={item.children}
                    /> :
                    <NavLink
                        key={index}
                        to={`${process.env.REACT_APP_BASE_URL}${item.url}`}
                        id={index}
                        className={ ({isActive}) => `${isActive ? "nav-item-active" : "nav-item"} nav-icon-${item.title.toLocaleLowerCase().replace(/ /g, '-').replace(/[\(\)\s]/g,"")}` }
                        state={item.title}
                        >
                        {item.title}
                    </NavLink>
                ))}
            </>
        )
    };

    return (
        <>
            {useCheckBreakpoint(768) ?
                <>
                    {isShowNavbar &&
                        <div className="nav-mobile">
                            {renderMenu()}
                        </div>
                    }
                </> :
                <div className="nav">
                    <a href={process.env.REACT_APP_BASE_URL} className="nav-logo">
                        <div className="nav-logo-icon"></div>
                    </a>
                    {renderMenu()}
                </div>
            }
        </>
    );
};

export default Menu;
