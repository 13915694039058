import React, { FC } from "react";

const DomainsInfo: FC = () => {
    return (
        <div className="stats-domains-info">
            <div className="stats-domains-info-item-allowed">
                <div>
                    <h5 className="stats-domains-info-item-allowed-title">Allowed domains</h5>
                    <p>Requests that passed through the web filter, as our algorithms detected no cyber threats or restrictions in your account's categories or domain settings.</p>
                </div>
            </div>
            <div className="stats-domains-info-item-blocked">
                <div>
                    <h5 className="stats-domains-info-item-blocked-title">Blocked domains</h5>
                    <p>Requests include instances identified as malicious by our algorithms or linked to blocked categories in your account settings.</p>
                </div>
            </div>
            <div className="stats-domains-info-item-mixed">
                <div>
                    <h5 className="stats-domains-info-item-mixed-title">Mixed domains</h5>
                    <p>Domains with dual statuses during the period – blocked & allowed or category changes, including those that revealed malicious traits during updates.</p>
                </div>
            </div>
        </div>
    )
};

export default DomainsInfo;
