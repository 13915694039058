// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-loader {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #E4E7EC;
}
.progress-loader-bar {
  position: absolute;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  height: 4px;
  background: #EC7D0D;
  animation: borealisBar 2s linear infinite;
}
@keyframes borealisBar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UI/progress-loader/ProgressLoader.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AAMJ;AAJI;EACI,kBAAA;EACA,MAAA;EACA,WAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,mBAAA;EACA,yCAAA;AAMR;AAHI;EACI;IACI,QAAA;IACA,WAAA;IACA,SAAA;EAKV;EAHM;IACI,QAAA;IACA,UAAA;IACA,UAAA;EAKV;EAHM;IACI,SAAA;IACA,SAAA;IACA,UAAA;EAKV;EAHM;IACI,UAAA;IACA,SAAA;IACA,SAAA;EAKV;AACF","sourcesContent":["@import '../../app/variables.scss';\n\n.progress-loader {\n    position: relative;\n    width: 100%;\n    height: 4px;\n    background-color: #E4E7EC;\n\n    &-bar {\n        position:absolute;\n        top:0;\n        right: 100%;\n        bottom: 0;\n        left: 0;\n        height: 4px;\n        background: #EC7D0D;\n        animation: borealisBar 2s linear infinite;\n    }\n\n    @keyframes borealisBar {\n        0% {\n            left: 0%;\n            right: 100%;\n            width: 0%;\n        }\n        10% {\n            left: 0%;\n            right: 75%;\n            width: 25%;\n        }\n        90% {\n            right: 0%;\n            left: 75%;\n            width: 25%;\n        }\n        100% {\n            left: 100%;\n            right: 0%;\n            width: 0%;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktop": `1200`,
	"tabletLarge": `1024`,
	"tabletSmall": `768`,
	"mobile": `576`
};
export default ___CSS_LOADER_EXPORT___;
