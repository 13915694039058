import React, { FC } from "react";

const HighlightsInfo: FC = () => {
    return (
        <div className="stats-highlights-info">
            <div className="stats-highlights-info-item-filtered">
                <div>
                    <h5 className="stats-highlights-info-item-filtered-title">Allowed requests</h5>
                    <p>Requests that passed through the web filter, as our algorithms detected no cyber threats or restrictions in your account's categories or domain settings.</p>
                </div>
            </div>
            <div className="stats-highlights-info-item-blocked">
                <div>
                    <h5 className="stats-highlights-info-item-blocked-title">Total blocked requests</h5>
                    <p>Requests that are flagged as potentially harmful by the SafeDNS algorithms or restricted based on the custom settings in your account.</p>
                </div>
            </div>
            <div className="stats-highlights-info-item-threats">
                <div>
                    <h5 className="stats-highlights-info-item-threats-title">Total threats prevented</h5>
                    <p>Total prevented threats include instances identified as malicious by our algorithms or linked to blocked cybersecurity categories in your account settings.</p>
                </div>
            </div>
        </div>
    )
};

export default HighlightsInfo;
