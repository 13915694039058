import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import axios from 'axios';
import { useActions } from '../../../hooks/useActions';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { StatsDevicesActionsTypes } from "../../../types/statsDevices";
import { renderDeviceName } from './DeviceItemName';
import Endpoints from '../../../api/endpoints';
import usePagination from '../../../hooks/usePagination';
import useSortable from '../../../hooks/useSortable';
import DeviceItem from './DeviceItem';
import Search from '../../UI/search/Search';
import ContentBlock from '../../UI/content-block/ContentBlock';
import InfoBlockAccordion from '../../UI/info-block-accordion/InfoBlockAccordion';
import Paginator from '../../UI/paginator/Paginator';
import Loader from '../../UI/loader/Loader';
import FetchError from '../../UI/fetch-error/FetchError';
import './Devices.scss';

const Devices: FC = () => {
    const dispatch = useDispatch();
    const { statsDevicesRequest } = useActions();
    const { STATS } = Endpoints;

    const token = useTypedSelector(state => state.auth.token);
    const profiles = useTypedSelector(state => state.profiles.profiles);
    const period = useTypedSelector(state => state.period.period);
    const requestData = useTypedSelector(state => state.statsRequestData.requestData);
    const deviceList = useTypedSelector(state => state.statsDevices.deviceList);

    const [searchDevices, setSearchDevices] = useState<string>("");
    const [devicesDataJson, setDevicesDataJson] = useState<string>("");
    const [devicesDataResult, setDevicesDataResult] = useState<any[]>([]);

    const loading = useTypedSelector(state => state.statsDevices.loading);
    const error = useTypedSelector(state => state.statsDevices.error);

    //get devices stats

    let recursionCount = 0;

    const getDevicesStats = () => {
        dispatch({
            type: StatsDevicesActionsTypes.GET_STATS_DEVICES
        });

        axios.post(`${process.env.REACT_APP_STATS_URL}${STATS.DEVICES}`, { ...requestData, order_by: "-visits" }, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then(response => {
            if (response.data.status.includes('complete')) {
                setDevicesDataJson(response.data.message);

                return dispatch({
                    type: StatsDevicesActionsTypes.GET_STATS_DEVICES_SUCCESS,
                    payload: response.data.message
                });
            } else {
                return setTimeout(() => {
                    recursionCount++

                    //exit from recursion with a long query
                    if (recursionCount >= 20) {
                        return dispatch({
                            type: StatsDevicesActionsTypes.GET_STATS_DEVICES_FAILURE,
                            payload: 'Oops! Something went wrong... Please, try later.'
                        });
                    } else {
                        return getDevicesStats();
                    }
                }, 3000);
            }
        })
        .catch(() => {
            dispatch({
                type: StatsDevicesActionsTypes.GET_STATS_DEVICES_FAILURE,
                payload: 'Oops! Something went wrong... Please, try later.'
            });
        });
    };

    useEffect(() => {
        if (period !== 'Range' && requestData.profile_ids.length !== 0) {
            getDevicesStats();
        }

        if (profiles.length !== 0) {
            statsDevicesRequest(getDevicesStats);
        }
    }, [requestData]);

    //get stats to json
    useEffect(() => {
        axios.get(devicesDataJson, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then((response) => {
            setDevicesDataResult(response.data);
            dispatch({
                type: StatsDevicesActionsTypes.STATS_DEVICES_DATA,
                payload: [...response.data].sort((a,b) => b.blocks - a.blocks).slice(0, 10)
            })

            dispatch({
                type: StatsDevicesActionsTypes.STATS_DEVICES_DATA_RESULT,
                payload: response.data
            })
        })
        .catch(() => {
            console.log('error');
        })
    }, [devicesDataJson]);

    //sorted devices to requests
    let { result, requestSort, sortConfig } = useSortable(devicesDataResult);

    const sortedDirectionClass = (name: string) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const searchDevicesHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchDevices(e.target.value)
    };

    //pagination
    const itemsCount = 10;
    const {
        firstContentIndex,
        lastContentIndex,
        nextPage,
        prevPage,
        page,
        gaps,
        setPage,
        totalPages,
    } = usePagination({contentPerPage: itemsCount, count: result.length});

    return (
        <ContentBlock
            title={"Devices"}
            padding="0"
            isLink={true}
            linkUrl="/dashboard/new_statistics/detailed"
            linkName='Detailed'
            content={
                <>
                    <div className="stats-devices">
                        <div
                            style={{
                                opacity: loading || error ? 0.2 : 1,
                                pointerEvents: loading || error ? 'none' : 'auto'
                            }}
                        >
                            <Search
                                value={searchDevices}
                                placeholder="Search Device"
                                margin="16px 32px"
                                width="325px"
                                searchHandler={(e: ChangeEvent<HTMLInputElement>) => searchDevicesHandler(e)}
                            />
                            <ul className="stats-devices-list-header">
                                <li></li>
                                <li>DEVICE</li>
                                <li
                                    className={`icon-sort ${sortedDirectionClass('requests')}`}
                                    onClick={() => {
                                        requestSort('requests');
                                    }}
                                >REQUESTS
                                </li>
                                <li
                                    className={`icon-sort ${sortedDirectionClass('blocks')}`}
                                    onClick={() => {
                                        requestSort('blocks');
                                    }}
                                >BLOCKED REQUESTS
                                </li>
                            </ul>

                            {!error && devicesDataResult.length === 0 ?
                                <div className="stats-devices-list-empty">
                                    <p>You don’t have stats yet</p>
                                </div> :
                                <div className="stats-devices-list">
                                    {result.length ? result
                                        .slice(firstContentIndex, lastContentIndex)
                                        .map((item, index) => (
                                            item.requests &&
                                            <DeviceItem
                                                key={index}
                                                device={
                                                    item.token !== 0 ? item.token :
                                                    item.ipv6[0] !== "::" ? item.ipv6 :
                                                    item.ipv4[0]
                                                }
                                                deviceName={
                                                    renderDeviceName(deviceList, item)
                                                }
                                                deviceType={
                                                    item.token !== 0 ? "token":
                                                    item.ipv6[0] !== "::" ? "ipv6" :
                                                    "ipv4"
                                                }
                                                requests={item.requests}
                                                blocks={item.blocks}
                                                sortChange={sortConfig}
                                                paginationChange={firstContentIndex}
                                            />
                                        )) :
                                        <div className="stats-devices-list-empty">
                                            <p>There are no useful results for your search</p>
                                        </div>
                                    }

                                    {result.length > itemsCount &&
                                        <div className="stats-devices-list-pagination">
                                            <div className="stats-devices-list-pagination-counts">
                                                <span>Showing</span>{`${firstContentIndex + 1}-${firstContentIndex + itemsCount}`}<span>of</span>{result.length}
                                            </div>
                                            <Paginator
                                                nextPage={nextPage}
                                                prevPage={prevPage}
                                                page={page}
                                                gaps={gaps}
                                                setPage={setPage}
                                                totalPages={totalPages}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                        </div>

                        {loading &&
                            <Loader
                                text='Please wait...'
                            />
                        }

                        {error &&
                            <FetchError
                                text={error}
                                isButton={true}
                                isButtonDisabled={loading ? true : false}
                                isIcon={false}
                                clickHeandler={getDevicesStats}
                            />
                        }
                    </div>
                    <InfoBlockAccordion
                        title="Detailed report on all of your devices."
                        padding="32px 32px 0"
                        is_content={false}
                    />
                </>
            }
        />
    )
};

export default Devices;
