import React, { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import './Menu.scss';

interface MenuItemProps {
    title: string;
    list: any[]
};

const MenuItem: FC<MenuItemProps> = ({ title, list }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <ul className={`nav-list`} onClick={() => setIsActive(!isActive)}>
            <div className={`nav-icon-${title.toLocaleLowerCase().replace(/ /g, '-').replace(/[\(\)\s]/g,"")}`}>
                <div className="nav-list-title">
                    {title}
                    <span className={`nav-list-${isActive ? "open" : "closed"}`}></span>
                </div>
            </div>

            {isActive &&
                list.map((child: any, index: number) => (
                    <li key={index}>
                        <NavLink
                            to={`${process.env.REACT_APP_BASE_URL}${child.url}`}
                            className={ ({isActive}) => `${isActive ? "nav-list-item-active" : "nav-list-item"}` }
                            state={child.title}
                        >
                            {child.title}
                        </NavLink>
                    </li>
                ))
            }

        </ul>
    );
};

export default MenuItem;
