import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const downloadPdf = () => {
    const dataCanvas = document.querySelector(".pdf-report-page");

    html2canvas(dataCanvas, { allowTaint: true, useCORS : true,}).then((canvas) => {
        const data = canvas.toDataURL("image/jpeg");
        const pdf = new jsPDF("portrait", "pt", [297*2.5, 1700*2.5], true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = 0;

        pdf.addImage(data, "JPEG", imgX, imgY, imgWidth * ratio, imgHeight * ratio, 'NONE', 'NONE');

        pdf.save("report.pdf");
    })

    // implementation with splitting the report into pages

    // const dataCanvas = document.querySelectorAll(".pdf-report-page");
    // let imgs = [];

    // (function createPdfReport(i) {
    //     if (i < dataCanvas.length) {
    //         html2canvas(dataCanvas[i]).then(canvas => {
    //             imgs[i] = canvas.toDataURL("image/png");
    //             createPdfReport(i + 1);

    //             const pdf = new jsPDF("p", "pt", "a4", true);
    //             const pdfWidth = pdf.internal.pageSize.getWidth();
    //             const pdfHeight = pdf.internal.pageSize.getHeight();
    //             const imgWidth = canvas.width;
    //             const imgHeight = canvas.height;
    //             const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
    //             const imgX = (pdfWidth - imgWidth * ratio) / 2;
    //             const imgY = 0;

    //             imgs.forEach((img) => {
    //                 pdf.addImage(img, "PNG", imgX, imgY, imgWidth * ratio, imgHeight * ratio).output('dataurlnewwindow');
    //             })

    //             pdf.save("report.pdf");
    //         });
    //     }
    // })(0);
};
