import { StatsRequestDataActions, StatsRequestDataActionsTypes, IInitialState } from "../../types/statsRequestData";

const initialState: IInitialState = {
    requestData: {
        tokens: [],
        period: 'today',
        profile_ids: [],
        start: new Date().toISOString().split('T')[0],
        end: new Date().toISOString().split('T')[0],
        group_by: 'hour',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        limit: 1000,
        domains: [],
        ipv4_addresses: [],
        ipv6_networks: [],
        file_types: [],
        cache_ttl: 180
    }
};

export const statsRequestDataReducer = (state = initialState, action: StatsRequestDataActions): IInitialState => {
    switch (action.type) {
        case StatsRequestDataActionsTypes.REQUEST_DATA:
            return {
                ...state,
                requestData: action.payload
            }
        default:
            return state
    };
};
