// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found {
  display: block;
  margin-top: 128px;
  text-align: center;
}
.not-found-title {
  margin-bottom: 12px;
  font-size: 72px;
  font-weight: 600;
  line-height: 80px;
  color: #0068FF;
}
.not-found-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: #333;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/404/404.scss","webpack://./src/components/app/variables.scss"],"names":[],"mappings":"AAEA;EACI,cAAA;EACA,iBAAA;EACA,kBAAA;AAMJ;AAJI;EACI,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cCJO;ADUf;AAHI;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,WCRa;ADarB","sourcesContent":["@import '../../app/variables.scss';\n\n.not-found {\n    display: block;\n    margin-top: 128px;\n    text-align: center;\n\n    &-title {\n        margin-bottom: 12px;\n        font-size: 72px;\n        font-weight: 600;\n        line-height: 80px;\n        color: $blue-primary;\n    }\n\n    &-text {\n        font-size: 20px;\n        font-weight: 500;\n        line-height: 28px;\n        color: $primary-text-color;\n    }\n}\n","//colors\n$white: #FFF;\n$black: #000;\n$orange: #F2994A;\n$button-primary-color: #0068FF;\n$button-secondary-color: #4081FF;\n$active-color: #1A75FF;\n$inactive-color: #828282;\n$blue-primary: #0068FF;\n$primary-color: #F6F9FF;\n$secondary-color: #ECF7FF;\n$primary-text-color: #333;\n$border-color: #BBB9B9;\n$border-color2: #E5E7EB;\n$disabled-color: #F9FAFB;\n\n// breakpoints\n$desktop-large: 1440px;\n$desktop: 1200px;\n$tablet-large: 1024px;\n$tablet-small: 768px;\n$mobile: 576px;\n\n:export {\n    desktop: 1200;\n    tabletLarge: 1024;\n    tabletSmall: 768;\n    mobile: 576;\n}\n\n@mixin desktop-large () {\n    @media (max-width: $desktop-large) {\n        @content;\n    }\n}\n\n@mixin desktop() {\n    @media (max-width: $desktop) {\n        @content;\n    }\n}\n\n@mixin tablet-large() {\n    @media (max-width: $tablet-large) {\n        @content;\n    }\n}\n\n@mixin tablet-small() {\n    @media (max-width: $tablet-small) {\n        @content;\n    }\n}\n\n@mixin mobile() {\n    @media (max-width: $mobile) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktop": `1200`,
	"tabletLarge": `1024`,
	"tabletSmall": `768`,
	"mobile": `576`
};
export default ___CSS_LOADER_EXPORT___;
