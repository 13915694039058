import React, { FC } from "react";
import './Footer.scss';

const Footer: FC = () => {
    const thisYear = new Date().getFullYear();

    return (
        <React.Fragment>
            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-content-info">
                        <p>© {thisYear} SafeDNS - All rights reserved</p>
                    </div>
                    <div className="footer-content-social">
                        <a className="footer-content-social-instagram" href="https://www.instagram.com/safedns/" target="_blank" rel="noreferrer"> </a>
                        <a className="footer-content-social-facebook" href="https://www.facebook.com/safednsofficial?mibextid=ZbWKwL" target="_blank" rel="noreferrer"> </a>
                        <a className="footer-content-social-twitter" href="https://twitter.com/safedns" target="_blank" rel="noreferrer"> </a>
                        <a className="footer-content-social-linkedin" href="https://www.linkedin.com/company/safedns" target="_blank" rel="noreferrer"> </a>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
};

export default Footer;
