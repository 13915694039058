import { FC } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Navigation from "../controls/Navigation";
import DataExportStepsBar from "./DataExportStepsBar";
import DataExportCard from "./DataExportCard";
import './DataExport.scss';

const DataExport: FC = () => {
    const s3IntegrationData = useTypedSelector(state => state.dataExportS3Integration.s3IntegrationReadData);

    return (
       <div className="data-export">
            <div className="controls">
                <Navigation />
            </div>
            <DataExportStepsBar />

            <div className="data-export-list">
                <DataExportCard
                    key="amazon_s3"
                    id="amazon_s3"
                    title="Amazon S3"
                    description="Connect SafeDNS and Amazon S3 to receive detailed logs every 10 minutes to your bucket"
                    isCompleted={s3IntegrationData ? true : false}
                    status={s3IntegrationData ? s3IntegrationData.is_valid ? "Connected" : "Disconnected" : ""}
                    lastExport={s3IntegrationData ? s3IntegrationData.last_export : ""}
                />
            </div>
       </div>
    );
};

export default DataExport;
