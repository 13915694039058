import React, { FC, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { NavbarProvider } from "../../contexts/navbarContext";
import { PdfReportProvider } from "../../contexts/pdfReportContext";
import { SlidingMenuProvider } from "../../contexts/slidingMenuContext";
import { ProtectedRoutes } from "../ProtectedRoutes";
import { checkRefreshToken } from "../../store/action-creators/auth";
import Header from "../header/Header";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import Stats from "../stats/Stats";
import Detailed from "../stats/detailed/Detailed";
import DataExport from "../data-export/DataExport";
import AmazonS3DataExportSettings from "../data-export/amazon-s3-integration/AmazonS3DataExportSettings";
import AmazonS3DataExportCheck from "../data-export/amazon-s3-integration/AmazonS3DataExportCheck";
import AmazonS3DataExportEdit from "../data-export/amazon-s3-integration/AmazonS3DataExportEdit";
import AmazonS3DataExportComplete from "../data-export/amazon-s3-integration/AmazonS3DataExportComplete";
import AmazonS3DataExportDelete from "../data-export/amazon-s3-integration/AmazonS3DataExportDelete";
import ModalContext from "../../contexts/modalContext";
import Account from "../account/Account";
import Notification from "../UI/notification/Notification";
import RecipientsModal from "../controls/RecipientsModal";
import NotFound from "../UI/404/404";
import PdfReport from "../pdf-report/PdfReport";
import './App.scss';

const App: FC = () => {
    //actions
    const {
        checkAccessToken,
        refreshToken,
        fetchAuthSession,
        fetchNavbarItems,
        fetchAdmins,
        fetchProfiles,
        fetchCategories,
        fetchNetworks,
        fetchAgentInfo,
        fetchAdUsers,
        checkRecipientsList,
        getPermissionsByFeatures,
        fetchS3IntegrationRead
    } = useActions();

    const token = useTypedSelector(state => state.auth.token); //access lifetime 5 minutes, refresh lifetime 1 day
    const requestData = useTypedSelector(state => state.statsRequestData.requestData);
    const permissions = useTypedSelector(state => state.auth.permissionsByFeatures);
    const s3IntegrationCreate = useTypedSelector(state => state.dataExportS3Integration.s3IntegrationCreateData);
    const s3IntegrationUpdate = useTypedSelector(state => state.dataExportS3Integration.s3IntegrationUpdateData);
    const s3IntegrationDelete = useTypedSelector(state => state.dataExportS3Integration.s3IntegrationDelete);

    const { isShowRecipientsModal, isShowS3IntegrationCompleteModal, isShowS3IntegrationDeleteModal } = useContext(ModalContext);

    //auth to JWT
    const planFeatures = (accessToken: string|null) => {
        if (accessToken) {
            return JSON.parse(atob(accessToken.split('.')[1])).plan_features
        }
    };

    useEffect((): void => {
        getPermissionsByFeatures(planFeatures(token.access))
    }, [token]);

    useEffect((): void => {
        if (token.refresh === null) {
            checkAccessToken();
        };

        if (!token.access || checkRefreshToken(token.access)) {
            if (token.refresh !== null) {
                refreshToken(token.refresh);
            }
        }
    }, [requestData])

    //auth session

    useEffect((): void => {
        fetchAuthSession();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useActions]);

    // const isAuth = useTypedSelector(state => state.auth.isAuth);
    const isAuthData = useTypedSelector(state => state.auth.authData);
    const recipientsList = useTypedSelector(state => state.statsMonthlyReport.recipientsList);

    //get navbar, profiles, networks, agent info, ad users
    useEffect((): void => {
        if (isAuthData.is_authenticated) {
            fetchNavbarItems();
            fetchCategories();
            fetchProfiles(isAuthData.user_id);
            fetchNetworks(isAuthData.user_id);
            fetchAgentInfo(isAuthData.user_id);
            fetchAdUsers(isAuthData.user_id);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthData, useActions]);

    //get admins, recipients list
    useEffect((): void => {
        if (isAuthData.is_authenticated) {
            fetchAdmins(isAuthData.user_id);
            checkRecipientsList(isAuthData.user_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthData, recipientsList]);

    //redirect to auth page
    useEffect((): void => {
        if (isAuthData.is_authenticated === false) {
            window.location.replace(`${process.env.REACT_APP_BASE_URL}/auth/signin/`);
        }
    }, [isAuthData]);

    //s3integration
    useEffect((): void => {
        if (isAuthData.is_authenticated) {
            fetchS3IntegrationRead(isAuthData.user_id);
        }
    }, [isAuthData, s3IntegrationCreate, s3IntegrationUpdate, s3IntegrationDelete]);

    return (
        <React.Fragment>
            {isAuthData.is_authenticated &&
                <NavbarProvider>
                    <SlidingMenuProvider>
                        <PdfReportProvider>
                            <div className="App">
                                <div className="layout">
                                    <Navbar />
                                    <div className="main-container">
                                        <div className="main-container-top">
                                            <Header />
                                            <Routes>
                                                <Route path="/" element={<ProtectedRoutes />}>
                                                    {permissions && permissions.statistics &&
                                                        <Route path="/dashboard/new_statistics" element={<Stats />}/>
                                                    }
                                                    {permissions && permissions.detailed_stats &&
                                                        <Route path="/dashboard/new_statistics/detailed" element={<Detailed />} />
                                                    }
                                                    {permissions && permissions.s3_integration &&
                                                        <>
                                                            <Route path="/dashboard/new_statistics/data-export" element={<DataExport />} />
                                                            <Route path="/dashboard/new_statistics/data-export/amazon_s3" element={<AmazonS3DataExportSettings />} />
                                                            <Route path="/dashboard/new_statistics/data-export/check/amazon_s3" element={<AmazonS3DataExportCheck />} />
                                                            <Route path="/dashboard/new_statistics/data-export/edit/amazon_s3" element={<AmazonS3DataExportEdit />} />

                                                            <Route path="/dashboard/new_statistics/data-export/*" element={<AmazonS3DataExportSettings />} />
                                                            <Route path="/dashboard/new_statistics/data-export/check/*" element={<AmazonS3DataExportCheck />} />
                                                        </>
                                                    }
                                                    <Route path="/account" element={<Account />} />
                                                    <Route path="*" element={<NotFound text={"Page not found"}/>} />
                                                </Route>
                                            </Routes>

                                            <PdfReport />
                                        </div>
                                        <div className="main-container-bottom">
                                            <Footer />
                                        </div>
                                    </div>
                                </div>
                                <Notification />

                                {/* POPUP AND MODALS */}
                                {isShowRecipientsModal &&
                                    <RecipientsModal />
                                }
                                {isShowS3IntegrationCompleteModal &&
                                    <AmazonS3DataExportComplete />
                                }
                                {isShowS3IntegrationDeleteModal &&
                                    <AmazonS3DataExportDelete />
                                }
                            </div>
                        </PdfReportProvider>
                    </SlidingMenuProvider>
                </NavbarProvider>
            }
        </React.Fragment>
    );
};

export default App;
