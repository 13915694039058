import { FC, useEffect, useContext } from "react";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useNavigate } from "react-router-dom";
import ModalContext from "../../../contexts/modalContext";
import Modal from "../../UI/modal/Modal";
import Button from "../../UI/button/Button";
import '../DataExport.scss';

const AmazonS3DataExportDelete: FC = () => {
    const {
        fetchS3IntegrationDelete
    } = useActions();

    const navigate = useNavigate();

    const isAuthData = useTypedSelector(state => state.auth.authData);
    const s3IntegrationDelete = useTypedSelector(state => state.dataExportS3Integration.s3IntegrationDelete);
    const { isShowS3IntegrationDeleteModal, setIsShowS3IntegrationDeleteModal } = useContext(ModalContext);

    useEffect(() => {
        if (s3IntegrationDelete !== null) {
            setIsShowS3IntegrationDeleteModal(!isShowS3IntegrationDeleteModal);
            navigate("/dashboard/new_statistics/data-export/");
        }
    }, [s3IntegrationDelete]);

    const onDeleteIntegration = () => {
        fetchS3IntegrationDelete(isAuthData.user_id);
    };

    return (
        <Modal
            title=""
            width="384"
            content={
                <div className="data-export-notice">
                    <div className="data-export-notice-icon-danger"></div>
                    <p className="data-export-notice-message">Are you sure you want to delete this service?</p>
                    <div className="data-export-notice-controls">
                        <Button
                            type="button"
                            name="Delete"
                            modifier="danger"
                            width="156px"
                            clickHeandler={onDeleteIntegration}
                        />
                        <Button
                            type="button"
                            name="Cancel"
                            modifier="secondary"
                            width="156px"
                            clickHeandler={()=> setIsShowS3IntegrationDeleteModal(!isShowS3IntegrationDeleteModal)}
                        />
                    </div>
                </div>
            }
        />
    );
};

export default AmazonS3DataExportDelete;
